@media print {
  html, body { margin: 0; padding: 0; height: 100%; width: 100%; }

  .print { display: inline; }

  .noprint {
    display: none !important;
  }

 .quick-reference-content { box-shadow: none !important;}
  .page-header,
  

  #quickspecs-toggle, #quickspecs header, #quickspecs #variant-nav,

  .article-toolbar-container,
  .nav, .loginInfo, .tmpspacer,
  .empty_sit_sub_menu_dropdown,
  #tocframe,
  .footer_links,

  // hide main page content when quickspecs is visible
  body.quickspecs #article_content,
  body.quickspecs #searchResult .rightCol,
  body.quickspecs #ssc_facets,


  #quickspecs {
    border: none;

    td, .gref, .noteref {
      color: #333 !important;
    }
  }

  #article_content { background: #fff; }
  #article_markup_content { border: 0; float: none; padding: 0; width: 100%; }
  
}